<!--
 * @Description 打卡机 串口写入
-->
<template>
  <!-- 选择设备类型 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>当前设备类型为：</span>
    </el-col>
    <el-col :span="10">
      <el-select v-model="deviceType" @change="changeDeviceType">
        <el-option
          v-for="item in selectData"
          :key="item.deviceType"
          :label="item.name"
          :value="item.deviceType"
        />
      </el-select>
    </el-col>
  </el-row>
  <!-- 串口是否可用 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口是否可用(刷新页面检测)：</span>
    </el-col>
    <el-col :span="10">
      <span>{{useSerialFlag}}</span>
    </el-col>
  </el-row>
  <!-- 串口连接状态 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口连接状态：</span>
    </el-col>
    <el-col :span="10">
      <span>{{serialStatus}}</span>
      <el-button type="primary" style="margin-left:16px" @click="openPort">打开串口</el-button>
    </el-col>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 填写和选择条件 -->
  <el-row justify="end">
    <el-col :span="8">
      <span>总灯控数量：</span>
      <el-input v-model="totalLightsNum" @blur="blurTotalLightsNum" style="width: 80px"></el-input>
    </el-col>
    <el-col :span="8">
      <span>景区：</span>
      <el-select v-model="scenicId" filter style="width: 150px" placeholder="请选择">
        <el-option
          v-for="item in initData.scenicSpotList"
          :key="item.code"
          :label="item.name"
          :value="item.code"
        />
      </el-select>
    </el-col>
    <el-col :span="6">
      <span>闪灯：</span>
      <el-select v-model="lightType" style="width: 90px" placeholder="请选择">
        <el-option
          v-for="item in initData.lightTypeList"
          :key="item.code"
          :label="item.name"
          :value="Number.parseInt(item.code, 2)"
        />
      </el-select>
    </el-col>
  </el-row>
  <!-- 单灯的数据设置列表 -->
  <el-row justify="center" v-if="paramsTableData.length">
    <el-col :span="22">
      <el-table :data="paramsTableData" max-height="220" stripe>
        <el-table-column label="序号" prop="sort" align="center" width="60"></el-table-column>
        <el-table-column label="颜色" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.color" placeholder="请选择">
              <el-option
                v-for="item in initData.colorList"
                :key="item.code"
                :label="item.name"
                :value="Number.parseInt(item.code, 2)"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="闪灯频率" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.frequency" placeholder="请选择">
              <el-option
                v-for="item in initData.frequencyList"
                :key="item.code"
                :label="item.name"
                :value="Number.parseInt(item.code, 2)"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="亮度" align="center">
          <template #default="scope">
            <el-select v-model="scope.row.brightness" placeholder="请选择">
              <el-option
                v-for="item in initData.brightnessList"
                :key="item.code"
                :label="item.name"
                :value="Number.parseInt(item.code, 2)"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="发射器编号" align="center">
          <template #default="scope">
            <el-input v-model="scope.row.number" maxlength="3"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <!-- 写入、读取 按钮 -->
  <el-row>
    <div style="margin: auto">
      <el-button type="primary" @click="writePort('write')">开始写入</el-button>
      <!-- <el-button type="primary" @click="writePort('read')">读取数据</el-button> -->
    </div>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 解析后的数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>解析后的数据：</span>
    </el-col>
    <el-col :span="10">
      <span :class="readInfo ? 'data-text' : ''">{{readInfo}}</span>
    </el-col>
  </el-row>
  <!-- 接收到的串口原始数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>接收到的串口原始数据：</span>
    </el-col>
    <el-col :span="10">
      <span :class="originalInfo ? 'data-text' : ''">{{originalInfo}}</span>
    </el-col>
  </el-row>
</template>

<script>
import { encrypt } from "../utils/PortControlUtil";
import { ElMessage } from "element-plus";

var port;
export default {
  name: "CardPunchConfig",
  data() {
    return {
      baseUrl: "https://www.zahuod.com/v1/",
      // baseUrl: "https://dev.zahuod.com/v1/",
      useSerialFlag: "检测中...",
      serialStatus: "串口未连接",
      readInfo: "",
      originalInfo: "",
      deviceType: 0,
      selectData: [
        {
          name: "打卡机",
          deviceType: 0,
        },
      ],
      readValue: "",
      //页面初始化数据
      initData: {},
      //总灯控数量
      totalLightsNum: "",
      //景区id
      scenicId: "",
      //闪灯类型：全亮、全灭、跑马灯...
      lightType: "",
      //单灯列表数据
      paramsTableData: [],
    };
  },
  created() {
    this.getInitData();
    this.readValue = this.selectData[0].readValue;
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      this.useSerialFlag = "串口可用";
    } else {
      this.useSerialFlag = "串口不可用";
    }
    this.serialStatus = "串口未连接";
  },
  methods: {
    //获取页面初始化数据
    getInitData() {
      let that = this;
      this.$request
        // .post(this.baseUrl + "iot-sign-in/write/init", {})
        .post("http://192.168.5.250:8086/v1/" + "iot-sign-in/write/init", {})
        .then((res) => {
          console.log("初始化数据 -->", res);
          if (res.data.code == 200) {
            that.initData = res.data.result;
          } else {
            console.error(res);
            alert("初始化接口报错->", res.data.msg);
          }
        })
        .catch((error) => {
          alert("接口没调成功");
          console.error(error);
        });
    },

    //选中设备类型
    changeDeviceType(deviceType) {
      //赋值读取SN的命令
      this.readValue = this.selectData[deviceType].readValue;
      console.log(this.selectData[deviceType]);
    },

    //总灯控数量 输入框 失去焦点
    blurTotalLightsNum() {
      let totalLightsNum = Number(this.totalLightsNum);
      if (totalLightsNum > 63) {
        alert("总灯控数量最多63个");
        return;
      }
      this.paramsTableData = [];
      for (let index = 0; index < totalLightsNum; index++) {
        this.paramsTableData.push({
          sort: index,
          color: index, //灯颜色
          frequency: 0, //闪灯频率
          brightness: 0, //亮度
          number: "", //发射器编号
        });
      }
    },

    //上报服务器写入成功
    ackSN() {
      var that = this;
      return;
      that.$request
        .post(that.baseUrl + "device/factory/code/bind-ack", {
          sn: that.currentWriteSN,
        })
        .then((res) => {
          console.log(
            "接口device/factory/code/bind-ack --- 响应数据 -->",
            res.data
          );
          if (res.data.code == 200) {
            that.readInfo = that.readInfo + "------>完全配置成功";
            that.scanQR = "";
          } else {
            console.log(that.currentWriteSN);
            that.readInfo =
              "SN:" +
              that.currentWriteSN +
              "写入设备成功,告知服务器时失败,请重试";
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert("接口没调成功");
          console.log(error);
        });
    },
    //开启串口,并打开接收监听
    async openPort() {
      var that = this;
      // 提示用户选择一个串口
      port = await navigator.serial.requestPort();
      try {
        // 等待串口打开
        await port.open({
          baudRate: 115200,
          dataBit: 8,
          stopBit: 1,
          parity: "none",
          flowControl: "none",
        });
      } catch (error) {
        alert("串口打开失败,请刷新页面重试");
      }

      while (port.readable) {
        this.serialStatus = "串口已连接";
        const reader = port.readable.getReader();
        console.log("尝试开始监听");

        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              // Allow the serial port to be closed later.
              reader.releaseLock();
              break;
            }
            if (value) {
              // value 是一个 Uint8Array
              console.log("value-->", value);
              for (let i = 0; i < value.length; i++) {
                that.originalInfo += value[i].toString() + ",";
              }
              if (that.originalInfo.indexOf("13") != -1) {
                //完全拼好了 再删除最后的逗号
                that.originalInfo = that.originalInfo.slice(0, -1);
              }
              console.log("that.originalInfo-->", that.originalInfo);
              if (that.originalInfo == "126,2,0,2,13") {
                //ACK回复,7e 02 00 02 0d,配置成功均回复此消息
                console.log(
                  "ACK回复,7e 02 00 02 0d / 126,2,0,2,13,配置成功均回复此消息"
                );
                that.readInfo = "数据写入成功";
                //返回给服务器
                // that.ackSN();
              } else if (
                value[0].toString() +
                  value[1].toString() +
                  value[2].toString() ==
                "1261213"
              ) {
                that.originalInfo = value;
                //7e 0c 0d ,读取到子机SN
                console.log("1261213 -- 7e 0c 0d ,读取到子机SN");
                var snArray = [];
                for (var i = 0; i < 6; i++) {
                  snArray[i] = value[3 + i];
                }
                console.log(snArray);
                that.readInfo = that.uint8ArrayToString(
                  new Uint8Array(snArray)
                );
              } else {
                that.readInfo = "正在接收ack回复消息";
              }
            }
          }
        } catch (error) {
          // TODO: Handle non-fatal read error.
          console.log("发生错误,error是:" + error);
        } finally {
          reader.releaseLock();
        }
      }
    },
    //向串口写入数据
    async writePort(type) {
      this.readInfo = "";
      this.originalInfo = "";
      //校验数据是否填写
      if (!this.checkForm()) return;
      //计算写入的数据
      let writeValue = this.calculateWriteValue();
      // return;
      var that = this;
      if (port == null) {
        alert("串口没开");
      } else {
        const writer = port.writable.getWriter();
        switch (type) {
          //读取子机SN
          case "read": {
            //直接写入十六进制数据,CRC直接填入计算,记录命令作用
            await writer.write(new Uint8Array(that.readValue));
            console.log(that.readValue);
            console.log("读取成功");
            break;
          }
          //写入
          case "write": {
            await writer.write(new Uint8Array(writeValue));
            console.log("写入成功");
            break;
          }
          default: {
            break;
          }
        }
        // 允许稍后关闭串口。
        writer.releaseLock();
      }
    },
    //计算写入数据
    calculateWriteValue() {
      //TODO Number(this.scenicId)
      //总灯控 0-5位(灯数量) 6-7位(闪灯模式)
      let lightTotal16 = (
        Number(this.totalLightsNum) |
        (this.lightType << 6)
      ).toString(16);
      let deviceInfoArr = [parseInt(lightTotal16, 16)];
      for (let index = 0; index < this.paramsTableData.length; index++) {
        const element = this.paramsTableData[index];
        //   color: "", //灯颜色 0-2位
        //   frequency: "", //闪灯频率 3-4位
        //   brightness: "", //亮度 5-6位
        //   number: "", //发射器编号 8-23位
        let cfb16 = (
          element.color |
          (element.frequency << 3) |
          (element.brightness << 5)
        ).toString(16);
        deviceInfoArr.push(parseInt(cfb16, 16));
        let number1 = (Number(element.number) & 0xff00).toString(16);
        deviceInfoArr.push(parseInt(number1, 16));
        let number2 = (Number(element.number) & (0xff00 >> 8)).toString(16);
        deviceInfoArr.push(parseInt(number2, 16));
      }
      // console.log("deviceInfoArr-->", deviceInfoArr);
      let writeValue = [
        0x7e,
        parseInt((1 + Number(this.totalLightsNum) * 3 + 2).toString(16), 16),
        0x41,
        ...deviceInfoArr,
      ];
      let crcArray = [];
      for (let i = 1; i < writeValue.length; i++) {
        // console.log("writeValue[i]->", writeValue[i]);
        crcArray.push(writeValue[i]);
      }
      let calculatedCRC = encrypt(crcArray);
      writeValue.push(calculatedCRC);
      writeValue.push(0x0d);
      console.log("writeValue-->", writeValue);
      return writeValue;
    },
    //判断是否全部填写
    checkForm() {
      //判断是否填写总灯控数量
      if (this.totalLightsNum.length == 0){
        ElMessage({
          message: "请填写总灯控数量",
          type: "warning",
        });
        return false;
      }
      //判断是否选择了景区
      if (this.scenicId.length == 0){
        ElMessage({
          message: "请选择景区",
          type: "warning",
        });
        return false;
      }
      //判断知否选择了闪灯类型
      if (this.lightType.length == 0){
        ElMessage({
          message: "请选择闪灯类型",
          type: "warning",
        });
        return false;
      }
      //判断是否填写完发射器编号
      if (this.paramsTableData.filter((item) => {
          return item.number.length == 0;
        }).length > 0){
        ElMessage({
          message: "请填写发射器编号",
          type: "warning",
        });
        return false;
      }
    },
    //uint8array转string
    uint8ArrayToString(fileData) {
      var dataString = "";
      console.log("fileData->", fileData);
      for (var i = 0; i < fileData.length; i++) {
        console.log("fileData[i]->", fileData[i]);
        dataString += String.fromCharCode(fileData[i]);
      }

      return dataString;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dotted-line {
  width: 90%;
  border: 1px dashed #999;
  margin: 32px auto;
}
</style>
