<!--
 * @Description 雨伞/登山杖 串口写入
-->
<template>
  <!-- 选择设备类型 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>当前设备类型为：</span>
    </el-col>
    <el-col :span="10">
      <el-select v-model="deviceType" @change="changeDeviceType">
        <el-option
          v-for="item in selectData"
          :key="item.deviceType"
          :label="item.name"
          :value="item.deviceType"
        />
      </el-select>
    </el-col>
  </el-row>
  <!-- 串口是否可用 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口是否可用(刷新页面检测)：</span>
    </el-col>
    <el-col :span="10">
      <span>{{useSerialFlag}}</span>
    </el-col>
  </el-row>
  <!-- 串口连接状态 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口连接状态：</span>
    </el-col>
    <el-col :span="10">
      <span>{{serialStatus}}</span>
      <el-button type="primary" style="margin-left:16px" @click="openPort">打开串口</el-button>
    </el-col>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 输入二维码 -->
  <el-row>
    <el-input
      style="height: 50px; width: 70%; margin:auto"
      v-model="scanQR"
      placeholder="请扫码获取二维码"
    />
  </el-row>
  <!-- 写入、读取 按钮 -->
  <el-row>
    <div style="margin: auto">
      <el-button type="primary" @click="getSN" style="margin-right: 16px">开始写入</el-button>
      <el-button type="primary" @click="writePort('read')">读取SN</el-button>
    </div>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 解析后的数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>解析后的数据：</span>
    </el-col>
    <el-col :span="10">
      <span :class="readInfo ? 'data-text' : ''">{{readInfo}}</span>
    </el-col>
  </el-row>
  <!-- 接收到的串口原始数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>接收到的串口原始数据：</span>
    </el-col>
    <el-col :span="10">
      <span :class="originalInfo ? 'data-text' : ''">{{originalInfo}}</span>
    </el-col>
  </el-row>
</template>

<script>
import { encrypt, ysdszEncrypt } from "../utils/PortControlUtil";

var port;
export default {
  name: "YSDSZConfig",
  data() {
    return {
      baseUrl: "https://www.zahuod.com/v1/",
      // baseUrl: "https://dev.zahuod.com/v1/",
      useSerialFlag: "检测中...",
      serialStatus: "串口未连接",
      readInfo: "",
      originalInfo: "",
      deviceType: 0,
      selectData: [
        {
          name: "雨伞/登山杖",
          deviceType: 0,
          value: [0x04, 0x01, 0xd0, ysdszEncrypt([0x04, 0x01, 0xd0])],
          readValue: [0x04, 0x01, 0xd0, ysdszEncrypt([0x04, 0x01, 0xd0])],
          writeType: "YSDSZ",
        },
      ],
      writeValue: "",
      readValue: "",
      scanQR: null,
      currentWriteSN: null,
      //当前写SN的类型,不同类型需要传的值不一样,流程也不太一样  "YSDSZ":雨伞登山杖
      currentWriteType: null,
      //当前从后台获取的配置资源
      currentConfigRes: null,
      //配置步数,一共两步
      currentStep: 1,
      currentIsRead: true,
    };
  },
  created() {
    this.writeValue = this.selectData[0].value;
    this.readValue = this.selectData[0].readValue;
    this.currentWriteType = this.selectData[0].writeType;
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      this.useSerialFlag = "串口可用";
    } else {
      this.useSerialFlag = "串口不可用";
    }
    this.serialStatus = "串口未连接";
    this.scanQR = null;
    this.currentWriteSN = null;
  },
  methods: {
    //选中设备类型
    changeDeviceType(deviceType) {
      //赋值读取SN的命令
      this.readValue = this.selectData[deviceType].readValue;
      this.writeValue = this.selectData[deviceType].value;
      this.currentWriteType = this.selectData[deviceType].writeType;
      this.scanQR = null;
      this.currentWriteSN = null;
      console.log(this.selectData[deviceType]);
    },
    //获取SN
    getSN() {
      console.log(this.scanQR);
      var that = this;
      if (this.scanQR == null || this.scanQR == "") {
        alert("拿二维码内容来换SN,现在空的");
      } else {
        that.$request
          .post(that.baseUrl + "device/factory/code/bind", {
            qrCode: that.scanQR,
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 200) {
              that.currentConfigRes = res;
              that.handleWrite(res);
            } else {
              alert(res.data.msg);
              that.scanQR = null;
              that.currentWriteSN = null;
            }
          })
          .catch((error) => {
            alert("获取配置信息失败");
            console.log(error);
          });
      }
    },
    handleWrite(res) {
      var that = this;
      if (that.currentWriteType == "YSDSZ") {
        //雨伞登山杖获取数据处理,准备写入
        //当前待写入的SN,后续写入成功上报服务器要用
        that.currentWriteSN = res.data.result;
        that.writePort(that.writeValue, false);
      } else {
        alert("配置异常参数");
      }
    },
    //雨伞/登山杖
    readSN(tagNumArray) {
      var readSNOrder = [0x0e, 0x01, 0xd3];
      readSNOrder = readSNOrder.concat(tagNumArray);
      readSNOrder.push(0x01);
      readSNOrder.push(0x02);
      readSNOrder.push(ysdszEncrypt(readSNOrder));
      this.writePort(readSNOrder, true);
    },
    //上报服务器写入成功
    ackSN() {
      var that = this;
      that.$request
        .post(that.baseUrl + "device/factory/code/bind-ack", {
          sn: that.currentWriteSN,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 200) {
            that.readInfo = that.readInfo + "------>完全配置成功";
            that.scanQR = "";
          } else {
            console.log(that.currentWriteSN);
            that.readInfo =
              "SN:" +
              that.currentWriteSN +
              "写入设备成功,告知服务器时失败,请重试";
            alert(res.data.msg);
          }
        })
        .catch((error) => {
          alert("接口没调成功");
          console.log(error);
        });
    },
    //开启串口,并打开接收监听
    async openPort() {
      var that = this;
      // 提示用户选择一个串口
      port = await navigator.serial.requestPort();
      try {
        // 等待串口打开
        await port.open({
          baudRate: 19200,
          dataBit: 8,
          stopBit: 1,
          parity: "none",
          flowControl: "none",
        });
      } catch (error) {
        alert("串口打开失败,刷新重试");
      }

      while (port.readable) {
        this.serialStatus = "串口已连接";
        const reader = port.readable.getReader();
        console.log("尝试开始监听");

        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              // Allow the serial port to be closed later.
              reader.releaseLock();
              break;
            }
            if (value) {
              // value 是一个 Uint8Array
              //原始数据
              this.originalInfo = value;
              //验证CRC是否正确
              var valueArray = value.toString().split(",");
              var getCRC = valueArray[valueArray.length - 1];
              var crcArray = [];
              for (let i = 0; i < valueArray.length - 1; i++) {
                console.log(i + ":" + parseInt(valueArray[i]).toString(16));
                crcArray.push(parseInt(valueArray[i]));
              }
              var calculatedCRC = ysdszEncrypt(crcArray);
              if (calculatedCRC != getCRC) {
                //CRC验证不通过
                this.readInfo = "数据验证不通过";
              } else if (
                value[0].toString() +
                  value[1].toString() +
                  value[2].toString() ==
                "131208"
              ) {
                //0D 01 D0 ,读取到标签
                var uuidArray = [];
                for (var i = 0; i < 8; i++) {
                  uuidArray[i] = value[4 + i];
                }
                this.readInfo = "读取到标签:" + uuidArray;

                if (this.currentIsRead) {
                  //读取SN
                  this.readSN(uuidArray);
                } else {
                  //写SN
                  var writeValue = [0x11, 0x01, 0xd4];
                  writeValue = writeValue.concat(uuidArray);
                  //写SN需要分两步写,第一步写前4位,第二步写后4位
                  this.currentStep = 1;
                  writeValue.push(0x01);
                  //写SN第一步,写前四位
                  for (var i = 0; i < 4; i++) {
                    writeValue.push(this.currentWriteSN.charCodeAt(i));
                  }
                  writeValue.push(ysdszEncrypt(writeValue));
                  this.writePort(writeValue, false);
                }
              } else if (value == "5,1,212,0,37") {
                //SN写入成功,根据写入步骤判断后续操作
                if (this.currentStep == 1) {
                  var writeValue = [0x11, 0x01, 0xd4];
                  //SN第一步写入成功
                  //进行SN第二步写入
                  writeValue = writeValue.concat(uuidArray);
                  //写SN需要分两步写,第一步写前4位,第二步写后4位
                  this.currentStep = 2;
                  writeValue.push(0x02);
                  //写SN第一步,写前四位
                  for (var i = 4; i < 6; i++) {
                    writeValue.push(this.currentWriteSN.charCodeAt(i));
                  }
                  writeValue.push(0x00);
                  writeValue.push(0x00);
                  writeValue.push(ysdszEncrypt(writeValue));
                  this.writePort(writeValue, false);
                } else {
                  //SN第二步写入成功,上报服务器
                  that.readInfo = "SN写入成功,正在上报服务器";
                  //返回给服务器
                  that.ackSN();
                }
              } else if (
                value[0].toString() +
                  value[1].toString() +
                  value[2].toString() ==
                "131211"
              ) {
                //0D 01 D3 ,读取到sn
                var snArray = [];
                for (var i = 0; i < 6; i++) {
                  snArray[i] = value[4 + i];
                }
                this.readInfo =
                  this.readInfo +
                  "<br/>" +
                  "读取到SN:" +
                  this.uint8ArrayToString(new Uint8Array(snArray));
              } else {
                //解析后的数据
                this.readInfo = "回复数据解析失败";
              }
            }
          }
        } catch (error) {
          // TODO: Handle non-fatal read error.
          console.log("发生错误,error是:" + error);
        } finally {
          reader.releaseLock();
        }
      }
    },
    //向串口写入数据
    async writePort(writeValue, currentIsRead) {
      this.currentIsRead = currentIsRead;
      var that = this;
      if (port == null) {
        alert("串口没开");
      } else {
        const writer = port.writable.getWriter();
        //直接写入标签
        await writer.write(new Uint8Array(writeValue));
        console.log(writeValue);
        console.log("写入成功");
        // 允许稍后关闭串口。
        writer.releaseLock();
      }
    },
    //uint8array转string
    uint8ArrayToString(fileData) {
      var dataString = "";
      for (var i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
      }

      return dataString;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dotted-line {
  width: 90%;
  border: 1px dashed #999;
  margin: 32px auto;
}
</style>
