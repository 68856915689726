    //子机和机柜计算CRC,异或
    function encrypt(bytes) {
        const len = bytes.length;
        var key = 0x00;
        for (var i = 0; i < len; i++) {
            key = key ^ bytes[i];
        }
        var tempString = key.toString(16);
        tempString = tempString.length < 2 ? "0" + tempString : tempString;
        return "0x" + tempString;
    }

    //雨伞和拐杖用到的CRC计算,累加后取反
    function ysdszEncrypt(bytes) {
        const len = bytes.length;
        var key = 0x00;
        for (var i = 0; i < len; i++) {
            key = key + bytes[i];
        }
        console.log(~key)

        return new Uint8Array([~key])[0]
    }


    module.exports = {
        encrypt: encrypt,
        ysdszEncrypt: ysdszEncrypt
    }