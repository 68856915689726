import {
    createApp
} from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'


import axios from 'axios'
import qs from 'qs'

const app = createApp(App)

app.config.globalProperties.$request = axios;
app.config.globalProperties.$qs = qs;
app.use(ElementPlus);


app.mount('#app')